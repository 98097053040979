import React, { useEffect, useState } from 'react';
import AuthService from '../../../services/auth.service';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/breadcrumbs';
import { Container, Row } from 'react-bootstrap';
import InputsService from '../../../services/inputs';
import "../index.scss";


const FillForm = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const [formData, setFormData] = useState('');


  const id = useParams();

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles[0];
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    const fetchFormData = async () => {
      const element_data = JSON.parse(localStorage.getItem('elementData'));
      if (element_data) {
        try {
          const Element = await InputsService.getFormData(id.id, element_data.element_id, element_data.event_id);
          if (Element.status === 200) {
            setFormData(Element.data.data)
          }
        } catch (error) {
        }
      }
    };

    fetchRole();
    fetchFormData();
  }, [navigate, id]);

  console.log(formData)

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser === "" || !roleUser.trim()) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate]);

  return (
    <Container className='container-custom-fluid'>
      <Row>
      <header className='header'>
          <div>
                <Breadcrumbs />
                <h1>Gestão de formulários</h1>
          </div>
        </header>
        <section className='container_card_element'>
        </section>
      </Row>
    </Container>
  );
};

export default FillForm;