import Select from 'react-select';
import "./index.scss";

function CustomInput({ options, onChange, value, label, isMultic = false, name, readonly}) {
  const handleChange = (selectedOption) => {
    // Adiciona o name ao chamar o onChange, se existir
    onChange({
      target: {
        name: name || "", // Se 'name' não existir, usa uma string vazia ou outro valor padrão
        value: isMultic
          ? selectedOption?.map(option => option.value)
          : selectedOption ? selectedOption.value : "" // Para single ou multi select
      }
    });
  };

  return (
    <div className='select'>
      {label && <label>{label}</label>}
      <Select
        isMulti={isMultic}
        value={isMultic
          ? options.filter(option => value && value.includes(option.value))
          : options.find(option => option.value === value)}
        onChange={handleChange}
        options={options}
        placeholder="Digite para buscar..."
        isSearchable={true}
        name={name || null}
        isDisabled={readonly}
      />
    </div>
  );
}

export default CustomInput;
