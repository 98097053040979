import React, { useEffect, useState } from 'react';
import AuthService from '../../../services/auth.service';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Button, Alert} from 'react-bootstrap';
import Breadcrumbs from '../../../components/breadcrumbs';
import CustomInput from '../../../components/input';
import UserService from '../../../services/user';
import CustomSelect from '../../../components/select';
import FrequencyService from '../../../services/frequency';

const EditFrequency = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const { id } = useParams(); 
  const [name, setName] = useState('');
  const [minutes, setMinutes] = useState('');
  const [idcreated, setIdCreated] = useState('');
  const [created, setCreated] = useState('');
  const [active, setActive] = useState(false);
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);


  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles[0];
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };

    fetchRole();
  }, [navigate]);

  useEffect(() => {
    if (currentUser && roleUser) {
        if (!roleUser.trim() || roleUser === "") {
            navigate('/');
        }
    } else if (!currentUser) {
      navigate('/login');
    } else {
      const fetchLocal = async () => {
        const local = await FrequencyService.Frequency(id);
        if(local.status === 200) {
          setName(local.data.data.name);
          setMinutes(local.data.data.minutes)
          setIdCreated(local.data.data.create_by);
          setActive(local.data.data.active);
        } else {
          setErros("Erro nos dados");
        }
      }

      fetchLocal();
    }
  }, [roleUser, currentUser, navigate, id]);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await UserService.User(idcreated);
      if (user.status === 200) {
        setCreated(user.data.data.name);
      }
    }

    fetchUser();
  }, [idcreated]);

  const optionsActive = [
    { label: 'Ativo', value: true },
    { label: 'Desativado', value: false },
  ];

  const handleStatusFrequency = async () => {
    let erros = {};

    try {
        const response = await FrequencyService.EditFrequency(
            id, 
            active.target.value
      );
      if (response.status === 200) {
        setErros([]);
        setAlert(true);
      } else {
        erros.submit = response.message;
      }
    } catch (error) {
      erros.submit = error.message;
    }
    
    if(erros.submit) {
      setErros(erros);
      setAlert(true)
    }
  }

  return (
    <Container className='container-custom-fluid'>
      <Row>
        <header className='header'>
          <div>
              <Breadcrumbs />
              <h1>{name}</h1>
          </div>
        </header>
        <section>
          <div className='container-panel'>
            <h2>Dados gerais</h2>
            <div className='container-panel-input'>
                <div> 
                    <CustomInput
                        label="Nome"
                        type="text"
                        value={name}
                        readOnly={true}
                        onChange= {(e) => setName(e.target.value)}
                    />
                </div>
                <div className='input_m'>
                    <CustomInput
                        label="Criado por"
                        type="text"
                        value={created}
                        readOnly={true}
                        onChange= {(e) => setCreated(e.target.value)}
                    />
                </div>
                <div className='input_p'>
                    <CustomInput
                        label="Minutos"
                        type="text"
                        value={minutes}
                        readOnly={true}
                        onChange= {(e) => setMinutes(e.target.value)}
                    />
                </div>
              </div>

              <div>
                    <CustomSelect 
                      label="Situação" 
                      options={optionsActive} 
                      value={active} 
                      onChange={setActive} 
                    />
                    {erros.active && <span className='texterror'>{erros.active}</span>}
              </div>

              <div className='container-alerts'>
                {alert && !erros.submit && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Local alterado com sucesso!</Alert>}
                {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
              </div>
              {roleUser.trim() && roleUser === "MASTER" && (
                <div className='container-panel-button'>
                    <Button variant="primary" onClick={handleStatusFrequency}>
                        Salvar alterações
                    </Button>
                </div>
              )}
            </div>
        </section>
      </Row>
    </Container>
  );
};

export default EditFrequency;