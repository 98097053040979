import { useCallback, useEffect, useState } from "react";
import "./index.scss";
import { Alert, Button } from "react-bootstrap";
import CloseIcon from "../../../../components/icons/close";
import TextArea from "../../../../components/textarea";
import CustomSelect from '../../../../components/select';
import Properties from "./properties";
import FlowchartService from "../../../../services/flowchart";
import { useParams } from "react-router-dom";
import Convert from "../../../../utils/convert";


const EditProperties = ({ show, handleClose, nameTask, element_id, type }) => {
    const [description, setDescription] =  useState('');
    const [successActive, setSuccessActive] = useState(false);
    const [erros, setErros] = useState({});
    const [active, setActive] = useState(true);
    const [element_bdid, setBdId] = useState('');
    const { id } = useParams(); 

    const optionsActive = [
        { label: 'Ativo', value: true },
        { label: 'Desativado', value: false },
    ];

    const fetchFlowchartElement = useCallback(async () => {
        setErros({});
        const flowchartElement = await FlowchartService.getFlowchartElement(id);
        if (flowchartElement) {
            let flowchartElements = flowchartElement.data.data;
            if (flowchartElements) {
                let elementProperties = flowchartElements.elements.find(element => element.extra_data.element_prid === element_id);
                if (elementProperties && elementProperties.properties) {
                    setBdId(elementProperties.element_id);
                    setDescription(elementProperties.description);
                    setActive(elementProperties.active);
                    if(elementProperties.properties.length > 0) {
                        localStorage.removeItem('properties');
                        localStorage.setItem('properties', JSON.stringify(elementProperties.properties));
                    }
                } else {
                    localStorage.removeItem('properties');
                    setBdId('');
                    setDescription('');
                    setActive('');
                }
            }
        } else {
          setErros('Não foi possível obter os elementos.');
        }
    },[element_id, id]);

    useEffect(() => {
        localStorage.removeItem('properties');
        fetchFlowchartElement();
    },[fetchFlowchartElement]);

    const handlesaveElement = async () => {
        let erros = {};

        let element_type = Convert.convertTypeElement(type);
        let properties = JSON.parse(localStorage.getItem('properties'));
        let element_prid = {
            "element_prid": element_id,
        }

        console.log(properties)
        //if(valid) {
            try {
                const response = await FlowchartService.newElement(
                    parseInt(id),
                    element_prid,
                    parseInt(element_bdid),
                    nameTask,
                    element_type,
                    properties,
                    description,
                    active.target.value,
                );
        
                if (response.status === 200) {
                    setErros({});
                    setSuccessActive(true);
                    fetchFlowchartElement();
                } else {
                    erros.submit = response.message;
                    setSuccessActive(true);
                }
            } catch (err) {
                //erros.submit('Erro ao salvar o elemento:', err);
            }
        //} else {
        //    setErros(erros);
        //}
    }
 
    return (
        <div className={`container_element ${!show ? 'container_hide' : ''}`}>
            <div className="container_element_title">
                <h2>{nameTask}</h2>
                <span onClick={handleClose}><CloseIcon /></span>
            </div>
            <div className="container_create_element">
                <div className="container_element_inputs">
                    <div>
                        <TextArea 
                            label="Descrição"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Descrição"
                            rows={2}
                        />
                        {erros.description && <span className='texterror'>{erros.description}</span>}
                    </div>
                </div>
                <div className='container-panel-select'>
                    <div>
                        <CustomSelect 
                        label="Situação" 
                        options={optionsActive} 
                        value={active} 
                        onChange={setActive} 
                        />
                        {erros.active && <span className='texterror'>{erros.active}</span>}
                    </div>
                </div>
            </div>
            <div className="container_create_properties">
                <Properties element_id={element_id} type={type} />
                {erros.properties && <span className='texterror'>{erros.properties}</span>}
            </div>
            <div className='container-alerts'>
                {successActive && !erros.submit && <Alert variant="success" onClose={() => setSuccessActive(false)} dismissible show={successActive}>Status alterados com sucesso!</Alert>}
                {successActive && erros.submit && <Alert variant="danger" onClose={() => setSuccessActive(false)} dismissible show={successActive}>{erros.submit}</Alert>}
            </div>
            <div className="container_create_element__button">
                 <Button variant="primary" onClick={handlesaveElement}>Salvar</Button>
            </div>
        </div>
    );
}  

export default EditProperties;