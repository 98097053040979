import React, { useEffect, useState } from 'react';
import AuthService from '../../../../services/auth.service';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../../components/breadcrumbs';
import { Button, Container, Row } from 'react-bootstrap';
import InputsService from '../../../../services/inputs';
import "../../index.scss";
import Convert from '../../../../utils/convert';
import FormPIcon from '../../../../components/icons/form_p';
import CloseEventIcon from '../../../../components/icons/close_event';

const Element = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [roleUser, setRoleUser] = useState('');
  const [dataElement, setDataElement] = useState('');

  const id = useParams();

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const role = await AuthService.verifyRole();
        if (role.status === 200) {
          const level = role.data.data.roles[0];
          setRoleUser(level);
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error("Failed to fetch role:", error);
        navigate('/login');
      }
    };
    const fetchElementItems = async () => {
      const element_data = JSON.parse(localStorage.getItem('elementData'));
      if (element_data) {
        try {
          const Element = await InputsService.getElement(id.id, element_data.element_id, element_data.event_id);
          if (Element.status === 200) {
            setDataElement(Element.data.data)
          }
        } catch (error) {
        }
      }
    };

    fetchRole();
    fetchElementItems();
  }, [navigate, id]);

  useEffect(() => {
    if (currentUser && roleUser) {
      if (roleUser === "" || !roleUser.trim()) {
        navigate('/login');
      }
    } else if (!currentUser) {
      navigate('/login');
    }
  }, [roleUser, currentUser, navigate]);

  if (!dataElement) {
    return <div>Carregando...</div>;
  }
  
  const closeEvent = async () => {
    const element_data = JSON.parse(localStorage.getItem('elementData'));
    if (element_data) {
      try {
        const Element = await InputsService.closeEvent(id.id, element_data.element_id, element_data.event_id);
        if (Element.status === 200) {
          setDataElement(Element.data.data)
        }
      } catch (error) {
      }
    }
  }

  const fillForm = () => {
    navigate(`/gestaoformularios/processo/elemento/formulario/${id.id}`);
  }

  return (
    <Container className='container-custom-fluid'>
      <Row>
      <header className='header'>
          <div>
                <Breadcrumbs />
                <h1>Gestão de formulários</h1>
          </div>
          <div className='container_header_close_event'>
            {roleUser.trim() && roleUser === "OPERATOR" && (
              <Button variant="outline-danger" size="md" onClick={closeEvent}><CloseEventIcon />Fechar evento</Button>
            )}
          </div>
        </header>
        <section className='container_card_element element'>
          <h2>Entradas</h2>
          <div className='container_cards_elements'>
            {dataElement.flow_inputs.length > 0 ? (
              dataElement.flow_inputs.map((input, index) => (
                <div className='container_cards_element'>
                  <div key={index} className={input.production_input.is_used ? 'card_flowchart card_open' : 'card_flowchart'}>
                    <div className="card_title">
                      <h2 className="flowchart-name">{input.production_input.name}</h2>
                    </div>
                    <div className="card_body">
                      <span><strong>Quantidade:</strong> {input.production_input.quantity}{input.production_input.measurement.symbol}</span>
                      <span><strong>Data de Criação:</strong> {Convert.convertDataBR(input.production_input.create_at)}</span>
                      <span><strong>Usado:</strong> {input.production_input.is_used ? 'Sim' : 'Não'}</span>
                      <span><strong>Aprovado:</strong> {input.production_input.approved ? 'Sim' : 'Não'}</span>
                    </div>
                    <div className="card-action">
                      {input.production_input.is_used ? (
                        <>
                          <div>
                            <Button variant="primary" size="md" onClick={fillForm}><FormPIcon />Preencher formulário</Button>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>Sem entradas registradas.</p>
            )}
          </div>

        <h2>Saídas</h2>
        <div className='container_cards_elements'>
            {dataElement.flow_outputs.length > 0 ? (
              dataElement.flow_outputs.map((output, index) => (
                <div className='container_cards_element'>
                  <div key={index} className='card_flowchart'>
                    <div className="card_title">
                      <h2 className="flowchart-name">{output.name}</h2>
                    </div>
                    <div className="card_body">
                      <span><strong>Quantidade:</strong> {output.quantity}{output.measurement.symbol}</span>
                      <span><strong>Data de Criação:</strong> {Convert.convertDataBR(output.create_at)}</span>
                      <span><strong>Usado:</strong>  {output.is_used ? 'Sim' : 'Não'}</span>
                      <span><strong>Aprovado:</strong> {output.approved ? 'Sim' : 'Não'}</span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>Sem saídas registradas.</p>
            )}
          </div>
        </section>
      </Row>
    </Container>
  );
};

export default Element;