import React, { useEffect, useState } from 'react';
import { Modal, Button, Alert} from 'react-bootstrap';
import CustomInput from '../../../../components/input';
import FlowchartService from '../../../../services/flowchart';
import { useNavigate } from 'react-router-dom';
import LocationService from '../../../../services/location';
import CustomSelect from '../../../../components/select';
import TextArea from '../../../../components/textarea';

const NewFlowchart = ({ onFlowchartCreated }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [erros, setErros] = useState({});
  const [alert, setAlert] = useState(false);
  const [name, setName] =  useState('');
  const [description, setDescription] =  useState('');
  const [local_id, setLocalId] =  useState('');
  const [dataLocations, setDataLocations] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      const location = await LocationService.getLocations();
      if (location) {
        let localSelect = location.data.data;
        let transformedLocations = localSelect.map(location => ({
          label: location.name,
          value: location.local_id,
        }));
        setDataLocations(transformedLocations)
      } else {
        setErros('Não foi possível obter os usuários.');
      }
    };

    fetchLocations();
  }, [navigate]);

  const handlenewFlowchart = async () => {
    let valid = true;
    let erros = {};
    
 
    if (!name.trim()) {
        erros.name = 'O nome é obrigatório';
        valid = false;
    }

    if (!description.trim()) {
        erros.description = 'A descrição é obrigatório';
        valid = false;
    }

    if (!local_id.target.value) {
        erros.local = 'O local é obrigatório';
        valid = false;
    }

    if (valid) {
      try {
        const response = await FlowchartService.newFlowchart (
            name,
            description,
            parseInt(local_id.target.value)
        );
        if (response.status === 200) {
            setErros({});
            setAlert(true);
            setName('');
            setDescription('');
            setLocalId('');
        } else {
            erros.submit = response.message;
            setErros(erros);
            setAlert(true);
        }
      } catch (error) {
          return error;
      }
    } else {
      setErros(erros);
    }

    onFlowchartCreated();
  };

  const handleClose = () => {
    setErros({});
    setShow(false);
    setName('');
    setDescription('');
    setLocalId('');
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Novo Processo
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Novo Processo</Modal.Title>
        </Modal.Header>
        <Modal.Body className='container_form_new_user'>
        <div>
              <CustomInput
                  label="Nome"
                  type="text"
                  placeholder="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
              />
              {erros.name && <span className='texterror'>{erros.name}</span>}
        </div>
        <div>
            <TextArea 
                label="Descrição"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Descrição"
                rows={5}
            />
              {erros.description && <span className='texterror'>{erros.description}</span>}
        </div>
        <div>
            <CustomSelect 
                label="Local" 
                options={dataLocations} 
                value={local_id} 
                onChange={setLocalId} 
              />
              {erros.local && <span className='texterror'>{erros.local}</span>}
        </div>
        <div>
            {alert && !erros.submit && <Alert variant="success" onClose={() => setAlert(false)} dismissible show={alert}>Local criado com sucesso!</Alert>}
            {alert && erros.submit && <Alert variant="danger" onClose={() => setAlert(false)} dismissible show={alert}>{erros.submit}</Alert>}
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sair
          </Button>
          <Button variant="primary" onClick={handlenewFlowchart}>
            Criar processo
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewFlowchart;